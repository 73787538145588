import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';

import { AuthenticationService } from './authentication/authentication.service';
import { UtilityService } from './services/utility.service';

export const authGuard: CanActivateChildFn = (route, state) => {
  const router = inject(Router),
    authenticationService = inject(AuthenticationService),
    uS = inject(UtilityService);
  if (authenticationService.isLoggedIn()) return true;
  else {
    router.navigateByUrl('/auth');
    return false;
  }
};
